<template>
  <section class="m-setting-clause m-scroll">
    <!-- 头部导航 -->
    <m-header title="About" ></m-header>
    <div class="main">
        <p class="t-i-32">
            <span>Mooby Yoho</span><span>established in May 2018 is an innovative cross-border Internet e-merchant service platform. </span>
        </p>
        <p class="t-i-32">
            <span>For those of you who have never heard of MOOBYYOHO before, we are a product discovery platform that helps you discover, save, and buy awesome products. </span><span class="color">😊</span><span>We are the original product discovery platform for staying up to date with the latest tech, gear, and most incredible crowdfunding campaigns.</span>
        </p>
        <p class="t-i-32">
            <span>MOOBYYOHO, </span><span>as an innovative cross-border e-merchant service platform, our business has covered whole Canada, aiming to provide brand new shopping experiences which is convenient and of high price-performance ratio. </span>
        </p>
        <p>
            <span>&nbsp;</span>
        </p>
        <p class="t-a-l">
            <strong><span>Company Adress：11121 Horseshoe Way Unit 133, Richmond, BC V7A 5G7</span></strong>
        </p>
        <p class="t-a-l">
            <strong><span>&nbsp;</span></strong>
        </p>
        <p class="t-a-c">
            <img width="98%" src="@/assets/spacer.png"/>
        </p>
        <p>
            <span>By Email：</span>
        </p>
        <p>
            <em><span>customerservice@moobyyoho.com</span></em>
        </p>
        <p>
            <span>By Wechat：</span>
        </p>
        <p>
            <em><span>mooby_yoho</span></em>
        </p>
        <p class="t-a-l">
            <span><span class="c-t">Cooperation:</span></span>
        </p>
        <p class="t-a-l">
            <em><span><span class="c-t">cooperate@moobyyoho.com</span></span></em>
        </p>
    </div>
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'

export default {
  name:'Clause',
  components:{ MHeader },
  data(){
    return {
      
    }
  }
}
</script>

<style lang="less" scoped>
.m-setting-clause {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top:44px;
  overflow-y: auto;
  .main {
    margin-top: 12px;
    width: 100%;
    padding: 0 16px;
    font-size: 16px;
  }
  .t-i-32{text-indent:32px}
  .color{color:rgb(34, 34, 34)}
  .t-a-l{text-align:left}
  .t-a-c{text-align: center;}
  .c-t{color:windowtext;text-underline:none;}
}
</style>